import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewData: state => state.listviewData.value,
    data: state => ({
      init: state.init.value,
      loading: state.loading.value,
      perPage: state.perPage.value,
      totalRows: state.totalRows.value,
      currentPage: state.currentPage.value,
      searchTxt: state.searchTxt.value,
      totalFieldsFilter: state.totalFieldsFilter.value,
      orderBy: state.orderBy.value,
      showListviewFilter: state.showListviewFilter.value,
      showListview: () => {
        if (state.totalRows.value > 0) {
          return true
        }

        return false
      },
      showPager: () => {
        if (state.totalRows.value > state.perPage.value) {
          return true
        }

        return false
      },
    }),
    txt: state => ({
      searchPlaceholder: state.txtSearchPlaceholder,
      loading: state.txtLoading,
      default: state.txtDefault,
      notFind: state.txtNotFind,
    }),
    fieldsOrder: state => state.fieldsOrder,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setLoadingListview(state, payload) {
      state.loading.value = payload
    },
    setListviewInit(state, payload) {
      state.init.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.orderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    addFilterSelectedTotalFields(state) {
      state.totalFieldsFilter.value += 1
    },
    resetFilterSelectedTotalFields(state) {
      state.totalFieldsFilter.value = 0
    },
    setShowListviewFilter(state, payload) {
      state.showListviewFilter.value = payload
    },
  },
  actions: {

    async loadListviewData({
      state, commit, dispatch, rootGetters,
    }, payload) {
      if (rootGetters['crm_leads_form/loaded'] === true) {
        await dispatch('request/cancelTokensPendingByUrl', 'listings/loadModalListings', { root: true })
      }

      const request = await new Promise((resolve, reject) => {
        commit('resetFilterSelectedTotalFields')

        const formData = new FormData()

        if ((payload !== null) && (payload !== undefined)) {
          if ('type' in payload) {
            if (payload.type === 'favorites') {
              formData.append('pageLoadSel', 'favorites')
              formData.append('fsw012s64', 1)
              formData.append('locationHUBByUser', 0)
            }
            if (payload.type === 'list') {
              formData.append('pageLoadSel', 'list')
              formData.append('fsw012s64', 1)
              formData.append('locationHUBByUser', 0)
            }
            if (payload.type === 'myHUB') {
              formData.append('pageLoadSel', 'myHUB')
              formData.append('fsw012s64', 1)
              formData.append('locationHUBByUser', 1)
            }
            if (payload.type === 'externas') {
              formData.append('fEstados[]', '5#1')
            }
          }
        }

        formData.append('start', ((state.currentPage.value - 1) * state.perPage.value))
        formData.append('length', state.perPage.value)

        if (state.searchTxt.value !== '') {
          formData.append('fsw012s02', state.searchTxt.value)
          commit('addFilterSelectedTotalFields')
        }

        if (state.orderBy.value.column !== undefined) {
          formData.append('orderByColumn', state.orderBy.value.column)
        }

        if (state.orderBy.value.sort !== undefined) {
          formData.append('orderBySort', state.orderBy.value.sort)
        }

        if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
          // Nao executa o loading
          commit('setListviewInit', 1)
        } else {
          commit('setListviewData', [])
          commit('setTotalRows', 0)
          commit('setLoadingListview', true)
          commit('setListviewInit', 1)
        }

        let customURL = 'listings/loadModalListings'
        if ('type' in payload && payload.type === 'externas') {
          customURL = 'listings/loadListingsExternas'
        }

        api.post(`${apiConfig.url_base_api}${customURL}`, formData)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                commit('setListviewData', response.data.data)
                commit('setTotalRows', response.data.recordsTotal)
                commit('setLoadingListview', false)
                commit('setListviewInit', 0)

                if (!!payload && (payload.init !== undefined) && (payload.init === true) && (response.data.recordsTotal > 0)) {
                  commit('setShowListviewFilter', true)
                }

                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar os imóveis')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os imóveis')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os imóveis')))
            }
          })
      })

      return request
    },

    listviewFilterOrderBy({ state, commit, dispatch }, payload) {
      if ((!!state.orderBy.value.column) && (state.orderBy.value.column === payload.field)) {
        if (state.orderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: payload.field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: payload.field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: payload.field,
        })
      }

      return dispatch('loadListviewData', payload || {})
    },

    setCurrentPage({ commit, dispatch }, payload) {
      if ((payload !== null) && (payload !== undefined)) {
        if ('page' in payload) {
          commit('setCurrentPage', payload.page)
        }
      }

      return dispatch('loadListviewData', payload || {})
    },

    setSearchTxt({ commit, dispatch }, payload) {
      if ((payload !== null) && (payload !== undefined)) {
        if ('txt' in payload) {
          commit('setSearchTxt', payload.txt)
        }
      }

      commit('setCurrentPage', 1)
      return dispatch('loadListviewData', { loadingDisable: true, ...payload || {} })
    },

  },
}
