<template>
  <div>

    <b-container
      fluid
      class="p-2"
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group
            :label="$t('Adicionar por')"
          >
            <v-select
              v-model="typeSearch"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="oAddNewListingBy"
              append-to-body
              :calculate-position="withPopper"
              label="txt"
              item-text="txt"
              item-value="id"
              @change="changeTypeSearch"
              @input="changeTypeSearch"
            >
              <template #option="{ txt }">
                {{ txt }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="showBlockZMID===true">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group
            :label="$t('Indique o ZMID')"
          >
            <b-input-group>
              <b-form-input
                v-model="pid"
                autocomplete="off"
                @keyup.enter="addNew(pid)"
              />
              <b-input-group-append>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  @click.stop.prevent="addNew(pid)"
                >
                  {{ $t('Adicionar') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <template
      v-if="showBlockGrid===true"
    >
      <div
        v-if="data.showListviewFilter === true"
        class="app-fixed-search d-flex align-items-center border-top-theme"
      >
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTxt"
              value=""
              :placeholder="txt.searchPlaceholder"
            />
          </b-input-group>
        </div>
        <div
          v-if="fieldsOrder.length > 0"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in fieldsOrder"
              :key="`field-order-sw031-${oField.name}`"
              @click="listviewFilterOrderBy(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderBy(oField.name)"
                :class="listviewIconOrderBy(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div class="ecommerce-application">
        <div
          v-if="data.showListview() === true"
          class="grid-view m-0 p-1"
        >
          <preview-Item
            v-for="(row, index) in listviewData"
            :key="row.id"
            :listing="{
              pid: row.pid,
              photo: row.image,
              price: row.currencyL + row.preco + row.currencyR,
              tipologia: row.tipologia,
              morada: row.morada,
              nickname: row.nickname,
              version: row.versoes,
              rating: row.rating,
              status: [{
                id: row.estado,
                txt: row.estado_txt
              }],
              agent: {
                nome: row.nameAgent,
                telefone: row.phoneAgent,
                imagem_300: row.imgAgent,
                allLocationHUB: row.moradaAgent
              }
            }"
            :index="index"
          >
            <template #blockActions>
              <div class="item-options text-center">
                <b-button
                  variant="primary"
                  tag="div"
                  class="btn-cart"
                  :style="{cursor: 'default' }"
                >
                  <span>{{ row.pid }}</span>
                </b-button>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  tag="a"
                  class="btn-cart"
                  @click.stop.prevent="addNew(row.pid)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-top">{{ $t('Adicionar') }}</span>
                </b-button>
              </div>
            </template>
          </preview-Item>
        </div>
        <div
          v-if="listviewData.length === 0"
          class="no-results text-center p-2"
          :class="{'show': !listviewData.length}"
        >
          <h5 v-if="(data.totalFieldsFilter > 0) && (data.init === 0)">
            {{ txt.notFind }}
          </h5>
          <h5 v-if="data.init === 1">
            {{ txt.loading }}
          </h5>
          <b-alert
            v-if="(data.totalFieldsFilter === 0) && (data.init === 0)"
            variant="primary"
            show
          >
            <div class="alert-body">
              <span>{{ txt.default }}</span>
            </div>
          </b-alert>
        </div>
      </div>

      <b-container
        v-if="data.showPager() === true"
        fluid
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center mt-2 mb-2"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="data.totalRows"
              :per-page="data.perPage"
              class="mb-0 pagination-white"
              :size="sizePaginationListviewResponsive"
            />
          </b-col>
        </b-row>
      </b-container>

    </template>

    <b-overlay
      :show="data.loading"
      no-wrap
    />

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BFormInput, BContainer, BButton, BPagination, BDropdown, BDropdownItem, BInputGroupPrepend, BInputGroup, VBTooltip, BFormGroup, BInputGroupAppend, BOverlay, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction, sizePaginationListviewResponsive, showMsgRequest } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { onUnmounted } from '@vue/composition-api'
import modelModalListingSearch from '@store-modules/listings/modalListingSearch'
import PreviewItem from '@/views/modules/components/cardListingSimple.vue'
import vSelect from 'vue-select'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { createPopper } from '@popperjs/core'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BContainer,
    BButton,
    BPagination,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    PreviewItem,
    BFormGroup,
    BAlert,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, sizePaginationListviewResponsive, showMsgRequest],
  props: {
    addNew: {
      type: Function,
      required: true,
    },
    tabsShow: {
      type: Array,
      default: () => [],
      required: false,
    },
    onlyExternal: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    scroll: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      pid: null,
      typeSearch: null,
    }
  },
  computed:
  {
    ...mapGetters('modalListingSearch', ['listviewData', 'data', 'txt', 'fieldsOrder', 'showFormCreateNew']),
    searchTxt: {
      get() { return this.$store.getters['modalListingSearch/data'].searchTxt },
      set(newValue) {
        this.$store.dispatch('modalListingSearch/setSearchTxt', { type: this.tabSelected, txt: newValue }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['modalListingSearch/data'].currentPage },
      set(newValue) {
        this.$store.dispatch('modalListingSearch/setCurrentPage', { type: this.tabSelected, page: newValue }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    tabSelected() {
      if ((this.typeSearch !== null) && (this.typeSearch !== undefined)) {
        if (('id' in this.typeSearch) && ('load' in this.typeSearch) && (this.typeSearch.load === true)) {
          return this.typeSearch.id
        }
      }

      return ''
    },
    showBlockZMID() {
      if ((this.typeSearch !== null) && (this.typeSearch !== undefined)) {
        if ('id' in this.typeSearch) {
          if (this.typeSearch.id === 'zmid') {
            return true
          }
        }
      }

      return false
    },
    showBlockGrid() {
      if ((this.typeSearch !== null) && (this.typeSearch !== undefined)) {
        if ('id' in this.typeSearch) {
          if (this.typeSearch.id === 'zmid') {
            return false
          }

          return true
        }
      }

      return false
    },
  },
  watch: {
    listviewData() {
      if (this.scroll !== null) {
        this.scroll.$el.scrollTop = 0
      }
    },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const oDropdownList = dropdownList
      oDropdownList.style.width = width
      oDropdownList.style.maxHeight = '175px'
      oDropdownList.style.zIndex = '9999'

      const popper = createPopper(component.$refs.toggle, oDropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          }],
      })

      return () => popper.destroy()
    },
    async listviewFilterOrderBy(fieldSort) {
      this.$store.dispatch('modalListingSearch/listviewFilterOrderBy', { type: this.tabSelected, field: fieldSort }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.data.orderBy.column === filterField) {
        if (this.data.orderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    async init() {
      try {
        await store.dispatch('modalListingSearch/loadListviewData', { type: this.tabSelected, init: true }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } catch (err) {
        //
      }
    },
    changeTypeSearch() {
      if ((this.typeSearch !== null) && (this.typeSearch !== undefined)) {
        if (('id' in this.typeSearch) && ('load' in this.typeSearch) && (this.typeSearch.load === true)) {
          this.init(this.typeSearch.id)
        }
      }
    },
  },
  setup(props) {
    const { t } = useI18nUtils()

    const ModelModalListingSearch = 'modalListingSearch'

    if (!store.hasModule(ModelModalListingSearch)) {
      store.registerModule(ModelModalListingSearch, modelModalListingSearch)

      onUnmounted(() => {
        if (store.hasModule(ModelModalListingSearch)) store.unregisterModule(ModelModalListingSearch)
      })
    }

    const oAddNewListingBy = []

    if (props.onlyExternal === true) {
      oAddNewListingBy.push({
        id: 'externas',
        txt: t('Angariações externas'),
        load: true,
      })
      // loadListingsExternas
    } else {
      oAddNewListingBy.push({
        id: 'zmid',
        txt: t('ZMID'),
        load: false,
      })

      if (props.tabsShow.includes('favorites')) {
        oAddNewListingBy.push({
          id: 'favorites',
          txt: t('Os meus favoritos'),
          load: true,
        })
      }

      if (props.tabsShow.includes('list')) {
        // Só surge este separador para o perfil de consultor / consultor leader
        const currentUser = store.getters['auth/currentUser']

        if ((currentUser !== null) && (currentUser !== undefined)) {
          if (('id_role' in currentUser) && (([4].includes(Number(currentUser.id_role)) && 'extras' in currentUser && 'basic' in currentUser.extras && Number(currentUser.extras.basic === 1)) || [7].includes(Number(currentUser.id_role)))) {
            oAddNewListingBy.push({
              id: 'list',
              txt: t('As minhas angariações'),
              load: true,
            })
          } else if (('id_role' in currentUser) && ([4].includes(Number(currentUser.id_role)) && 'extras' in currentUser && 'idLeader' in currentUser.extras && currentUser.extras.idLeader !== null && currentUser.extras.idLeader !== '')) {
            oAddNewListingBy.push({
              id: 'list',
              txt: t('Angariações do meu Líder'),
              load: true,
            })
          }
        }
      }

      if (props.tabsShow.includes('myHUB')) {
        oAddNewListingBy.push({
          id: 'myHUB',
          txt: t('Angariações do meu HUB'),
          load: true,
        })
      }

      if (props.tabsShow.includes('externas')) {
        oAddNewListingBy.push({
          id: 'externas',
          txt: t('Angariações externas'),
          load: true,
        })
      }
    }

    return {
      oAddNewListingBy,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>
